<template>
  <header id="header" :class="scrollDirection">
    <div id="top-mobile-header" v-if="currentPage == 'home'">
      <img id="title-logo" src="../assets/bliss.webp" />
    </div>

    <div id="toggleview-container" v-if="currentPage == 'home' || currentPage == 'feed'">
      <span @click="toggleView('alt')" :class="{ active: currentView === 'alt' }">For you</span>
      <span @click="toggleView('recent')" :class="{ active: currentView === 'recent' }">Following</span>

    </div>

    <div id="user-header" v-else-if="currentPage == 'about'">
      <div id="back-icon-container" @click.prevent="$emit('changePage', 'home')">
        <img id="back-arrow" src="../assets/back-arrow.png" />
      </div>
      <div id="user">
        <div id="username">justine</div>
        <div id="userstats">90 searches</div>
      </div>

    </div>

  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    currentView: String,
    currentPage: String,
    headlineText: {
      type: String,
      default: ''
    },
    scrollDirection: String,
  },
  methods: {
    toggleView(view) {
      this.$emit('toggleView', view);
    }
  }
};
</script>

<style scoped>
#header {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid #f7f6f6;
  font-size: 14px;
  flex-direction: column;
  gap: 30px;
  transition: background-color 0.3s ease;
}

#toggleview-container {
  display: flex;
  width: 100%;
  gap: 15%;
  justify-content: center;
  font-family: 'Mukta';
}

#toggleview-container span {
  cursor: pointer;
  color: grey;
  padding: 0 10px;
  position: relative;
  line-height: 1.2;
  text-align: center;
  /* Ensure proper alignment */
}

#toggleview-container span.active {
  color: black;
}

#toggleview-container span.active::before {
  content: '';
  display: block;
  height: 3px;
  background-color: rgb(23, 170, 20);
  width: 100px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

#toggleview-container span:hover {
  background-color: rgba(198, 197, 197, 0.1);
  border-radius: 50px;
}

#top-mobile-header {
  display: none;
  /* Hidden by default */
  justify-content: center;
  align-items: center;
}

#user-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: left;
  justify-items: left;
  justify-content: flex-start;
  padding: 5px;
}

#back-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  border-radius: 50px;
  transition: background-color 0.2s ease;
  gap: 10px;
  padding: 10px;
}

#back-icon-container:hover {
  background-color: rgba(171, 170, 170, 0.2);
}

#back-icon-container img {
  width: 24px;
  height: 24px;
}

#user {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

#username {
  font-weight: bold;
  font-size: 18px;
  color: black;
}

#userstats {
  color: grey;
}




@media (max-width: 768px) {
  #header.scroll-down {
    display: none;
  }

  #header.scroll-up {
    display: flex;
  }

  #top-mobile-header {
    display: flex;
  }

  #header {
    font-size: 18px;
  }

  #title-logo {
    width: 20px;
    height: 20px;
  }

}
</style>
