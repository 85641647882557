<template>
  <!-- total element in the center of page-->
  <div class="user-profile">
    <img class="user-banner" src="@/assets/bliss.webp">
    <img class="user-pfp" src="@/assets/headshot2.jpg">

    <div class="user-text">
      <div class="user-ID">
        <span id="name"><b>Justine Jung</b></span>
        <span id="username">@psychobath</span>
      </div>

      <div class="user-links">
        <div class="user-link" id="user-location">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 7c-1.93 0-3.5 1.57-3.5 3.5S10.07 14 12 14s3.5-1.57 3.5-3.5S13.93 7 12 7zm0 5c-.827 0-1.5-.673-1.5-1.5S11.173 9 12 9s1.5.673 1.5 1.5S12.827 12 12 12zm0-10c-4.687 0-8.5 3.813-8.5 8.5 0 5.967 7.621 11.116 7.945 11.332l.555.37.555-.37c.324-.216 7.945-5.365 7.945-11.332C20.5 5.813 16.687 2 12 2zm0 17.77c-1.665-1.241-6.5-5.196-6.5-9.27C5.5 6.916 8.416 4 12 4s6.5 2.916 6.5 6.5c0 4.073-4.835 8.028-6.5 9.27z" />
          </svg>
          brooklyn
        </div>
        <div class="user-link" id="user-url">
          <svg width="14" height="14" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.36 5.64c-1.95-1.96-5.11-1.96-7.07 0L9.88 7.05 8.46 5.64l1.42-1.42c2.73-2.73 7.16-2.73 9.9 0 2.73 2.74 2.73 7.17 0 9.9l-1.42 1.42-1.41-1.42 1.41-1.41c1.96-1.96 1.96-5.12 0-7.07zm-2.12 3.53l-7.07 7.07-1.41-1.41 7.07-7.07 1.41 1.41zm-12.02.71l1.42-1.42 1.41 1.42-1.41 1.41c-1.96 1.96-1.96 5.12 0 7.07 1.95 1.96 5.11 1.96 7.07 0l1.41-1.41 1.42 1.41-1.42 1.42c-2.73 2.73-7.16 2.73-9.9 0-2.73-2.74-2.73-7.17 0-9.9z">
            </path>

          </svg>

          <a href="https://storeinacooldryplace.cargo.site">portfolio</a>
        </div>

      </div>

      <!-- User stats with border and troubleshooting styles -->
      <div class="user-stats debug">
        <span class="stat"><b>2 people</b> here right now</span>
        <span class="stat"> <b>{{ timeSinceLastSearch }} </b> since last search </span>
        <span class="stat"><b>2.6k </b>visitors</span>
        <span class="stat"><b>33</b> followers</span>
        <span class="stat"><b>{{ totalReactions }}</b> likes</span>
        <span class="stat"><b>{{ totalSearches }} searches</b> since beginning</span>
        <span class="stat"><b>323 days</b> until the website goes down</span>
      </div>


    </div>
  </div>


  <div id="about">

    <div class="tweet">

      <img class="user-pfp" src="@/assets/headshot2.jpg">

      <div id="tweet-main-column">

        <div class="user-ID">
          <span id="name"><b>Justine Jung</b></span>
          <span id="username">@psychobath</span>
        </div>

        <div id="tweet-content">

          Search History is a conceptual web performance by Justine Jung. Between November 7, 2024 to November 7, 2025,
          she publishes her Google searches in real-time here for anyone with an Internet connection to see. A critical
          examination of the boundaries of privacy, surveillance, and exhibitionism in the digital age, Jung confronts the
          absurdity of voluntarily exposing one's most private thoughts to the public. Using techniques of satire and
          parody, the piece mimics the familiar grammar and interface of social media while amplifying the unsettling act
          of surrendering privacy in exchange for attention.
        </div>



      </div>
      <div id="time">3 days ago</div>

    </div>

  </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, onSnapshot, query, orderBy, limit } from 'firebase/firestore';

export default {
  name: 'AboutPage',
  data() {
    return {
      totalReactions: 0,
      totalSearches: 0,
      timeSinceLastSearch: ''
    };
  },
  mounted() {
    this.getTotalStats();
    this.getTimeSinceLastSearch();
  },
  methods: {
    getTotalStats() {
      const postsCollection = collection(db, 'posts');

      // Listen to real-time updates for all documents in the "posts" collection
      onSnapshot(postsCollection, (snapshot) => {
        let totalReactionsCount = 0;
        let totalDocsCount = snapshot.size; // Get the total number of documents

        // Loop through each document in the collection
        snapshot.forEach((doc) => {
          const data = doc.data();
          const emojiReactions = data.emojiReactions;

          // Sum the emoji reactions (heart, question, surprise)
          if (emojiReactions) {
            totalReactionsCount += emojiReactions.heart || 0;
            totalReactionsCount += emojiReactions.question || 0;
            totalReactionsCount += emojiReactions.surprise || 0;
          }
        });

        // Update the totalReactions and totalSearches variables in real-time
        this.totalReactions = totalReactionsCount;
        this.totalSearches = totalDocsCount; // Update total searches (docs)
      });
    },
    getTimeSinceLastSearch() {
      const postsCollection = collection(db, 'posts');
      const q = query(postsCollection, orderBy('visitTime', 'desc'), limit(1)); // Query the most recent search

      onSnapshot(q, (snapshot) => {

        console.log("here's snapshot first docs: ", snapshot.docs[0])


        if (!snapshot.empty) {
          const mostRecentDoc = snapshot.docs[0];
          const visitTime = mostRecentDoc.data().visitTime;

          // Get the current timestamp and compute the difference
          const currentTime = Date.now();
          const timeDifference = currentTime - visitTime;

          this.timeSinceLastSearch = this.formatTimeSince(timeDifference);
        } else {
          this.timeSinceLastSearch = 'No searches yet';
        }
      });
    },

    formatTimeSince(milliseconds) {
      const seconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days > 0) {
        return `${days} days ago`;
      } else if (hours > 0) {
        return `${hours} hours ago`;
      } else if (minutes > 0) {
        return `${minutes} minutes ago`;
      } else {
        return `${seconds} seconds ago`;
      }
    },
  },
};
</script>

<style scoped>
.user-profile {
  margin: 0px;
  border-bottom: 1px solid #e6e5e5;
  gap: 3px;
}


.user-ID {
  display: flex;
  flex-direction: column;
  /* Debug border */
}

.user-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: #333;
  margin-left: 20px;
  /* Debug border */
}

.user-stats {
  display: inline-flex;
  /* Prevents margin collapsing */
  flex-wrap: wrap;
  gap: 30px;
  /* Debug border */
  overflow: hidden;
  line-height: 1.2;
  margin-bottom: 15px;
  color: rgb(83, 100, 113)
}

.user-stats b {
  color: #333
}

a {
  color: rgb(29, 155, 240);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.stat {
  line-height: 1.2;
}

.user-links {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
  color: rgb(83, 100, 113);
}

.user-links svg {
  width: 20px;
  height: 20px;
  fill: rgb(83, 100, 113);
}

.user-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3px;
}

.user-banner {
  width: 100%;
  height: 200px;
  background-color: #e1e8ed;
  border-radius: 0%;
  margin-bottom: -40px;
}

.user-pfp {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  object-fit: cover;
  /* Changed to "contain" for better fit without distortion */
  margin-left: 20px;
  margin-top: -50px;
  aspect-ratio: 1 / 1;
}

.user-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Evenly distributes space between child elements */
  font-size: 16px;
  color: #333;
  margin-left: 20px;
  gap: 10px;
}

.user-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
}

#name {
  font-size: 20px;
}

#username {
  color: grey
}

#about {
  margin: 20px
}


p {
  font-size: 40px;
  width: 70%;
}




.tweet img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 0;
  align-items: flex-start;
}


.tweet #name {
  font-size: 16px;
}

.tweet #username {
  font-size: 14px;
  margin: 0;
}

#tweet-main-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tweet {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
  align-items: flex-start;
}

#tweet-content {
  font-size: 25px;
  margin: 0;
  padding-top: 5px;
  width: 120%;
  line-height: 1.5;
}

#time {
  font-size: 14px;
  color: grey;
  align-self: flex-start;
  white-space: nowrap;
  margin-left: 10px;
}
</style>