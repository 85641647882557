<template>
  <div id="timeline">
    <DateItem v-for="(dateArr, index) in groupedHistory" :key="index" :date="dateArr[0].date" :items="dateArr" />
  </div>
</template>

<script>
import DateItem from './DateItem.vue';

export default {
  name: 'TimelineComponent',
  components: {
    DateItem,
  },
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
  watch: {
    history: {
      immediate: true, // Run immediately when `history` is available
      handler() {
        console.log("i'm about to assign images now")
        this.assignImages(this.history);
      },
    },
  },
  methods: {
    assignImages(historyItems) {
      historyItems.forEach(item => {
        item.getsImage = false;
      })

      const basePosition = 3;
      for (let i = basePosition; i < historyItems.length;) {
        const interval = Math.floor(Math.random() * 3) + 1; // Random interval between 3 and 6
        const randomOffset = Math.floor(Math.random() * 3) - 1; // ±1 variation
        const targetIndex = i + randomOffset;

        if (targetIndex >= 0 && targetIndex < historyItems.length) {
          historyItems[targetIndex].getsImage = true;
          console.log("in timeline: i decided i want an image for: ", historyItems[targetIndex].title);
        }

        i += interval; // Move to the next base position with a random interval
      }
    }
  },
  computed: {
    groupedHistory() {
      const dateWise = [];
      let lastIndex = 0;

      console.log("before TL works. this is TL's history: ", this.history)

      for (let i = 0; i < this.history.length; i++) {
        const currentItem = this.history[i];
        if (i === 0) {
          dateWise.push([currentItem]);
        } else {
          const prevDate = this.history[i - 1].date;

          if (currentItem.date === prevDate) {
            dateWise[lastIndex].push(currentItem);
          } else {
            lastIndex++;
            dateWise.push([currentItem]);
          }
        }
      }
      console.log("after the restructure, here's TLs dateWise: ", dateWise)
      return dateWise;
    },
  },
}
</script>

<style>
#timeline {
  margin-left: 10px;
}

@media (max-width: 768px) {
  #timeline {
    margin-left: 0px;
  }
}
</style>