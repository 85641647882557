<template>
  <div class="explore-container">
    <div class="search-box" :class="{ 'focused': isFocused }">
      <img id="search-icon" src="@/assets/magnifying.svg">
      <input type="text" class="search-input" placeholder="Search" v-model="searchQuery" @focus="isFocused = true"
        @blur="isFocused = false" @input="handleSearch" />
    </div>

    <TrendingSearches />
  </div>
</template>

<script>

import TrendingSearches from './TrendingSearches.vue'

export default {
  name: 'ExploreMenu',
  components: {
    TrendingSearches,
  },
  data() {
    return {
      searchQuery: '',
      isFocused: false,
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchQuery)
    },
    clearSearch() {
      this.searchQuery = ''
      this.handleSearch()
    },
  }
}
</script>

<style scoped>
.explore-container {
  margin: 0px 5px;
}

.search-box {
  background-color: #efefef;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  transition: background-color 0.2s;
  height: 30px;
  gap: 5px;
}

.search-box.focused {
  background-color: #efefef;
  border: 1px solid #1da1f2;
}

#search-icon {
  width: 15px;
  height: 15px;
}

.search-input {
  background: transparent;
  border: none;
  font-size: 15px;
  width: 100%;
  outline: none;
}

.search-input::placeholder {
  color: #71767b;
}

.clear-icon {
  width: 5px;
  height: 5px;
  cursor: pointer;
}

.clear-svg {
  fill: #1da1f2;
  width: 100%;
  height: 100%;
}
</style>