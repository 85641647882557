<template>
  <div id="app-container">
    <LeftSidebar :scrollDirection="scrollDirection" @changePage="currentPage = $event" />

    <main id="main-content">
      <div id="header-wrapper">
        <HeaderComponent :currentPage="currentPage" :currentView="currentView" :scrollDirection="scrollDirection"
          @toggleView="toggleView" @changePage="currentPage = $event" />
      </div>

      <div v-if="currentPage === 'home'">
        <TimelineComponent v-if="currentView === 'recent' && history.every(entry => entry.postId !== '0')"
          class="timeline" :history="history" />
        <AltTimeline v-else-if="currentView === 'alt' && history.every(entry => entry.postId !== '0')"
          class="alt-timeline" :history="history" />
      </div>

      <div v-else-if="currentPage === 'explore'">
        <ExploreMenu :currentPage="currentPage" />
      </div>

      <div v-else-if="currentPage === 'about'">
        <AboutPage :activeUsers="activeUsers" :totalVisitors="totalVisitors" />
      </div>

      <div v-else-if="currentPage === 'feed'">
        <TweetTimeline class="tweet-timeline" :history="history" />
      </div>
    </main>

    <RightSidebar :currentPage="currentPage" />
  </div>
</template>

<script>
import TimelineComponent from './components/TimelineComponent.vue';
import AltTimeline from './components/AltTimeline.vue';
import AboutPage from './components/AboutPage.vue';
import { db } from '@/firebase'; // Import your Firebase configuration
import { doc, setDoc, getDoc } from 'firebase/firestore';
import LeftSidebar from './components/LeftSidebar.vue';
import RightSidebar from './components/RightSidebar.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import ExploreMenu from './components/ExploreMenu.vue';
import TweetTimeline from './components/TweetTimeline.vue';

const APIURL = "https://staging.searchhistory.site/api"
// this will have to be moved to dotenv later
// this will also have to updated via nginx and dist via proxy

export default {
  name: 'App',
  components: {
    TimelineComponent,
    AltTimeline,
    TweetTimeline,
    LeftSidebar,
    RightSidebar,
    AboutPage,
    HeaderComponent,
    ExploreMenu,
  },
  data() {
    return {
      history: [],
      currentPage: 'home',
      currentView: 'recent',
      activeUsers: 0,
      totalVisitors: 0,
      scrollDirection: "scroll-up",
      lastScrollY: 0,
    };
  },
  mounted() {
    this.fetchData(); // Fetch API data on component mount
    this.connectSSE();
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(APIURL);
        const data = await response.json();
        const updatedData = []; // Array to store updated data
        // Flag to check if a document exist
        console.group(data)

        for (const d of data) {
          // Use d.visitItem.id as the unique ID for Firestore documents
          if (!d.visitItem || !d.visitItem[0] || !d.visitItem[0].id) {
            console.error(`Missing visitItem[0] or visitItem[0].id for entry:`, d);
            continue; // Skip this entry if visitItem[0] or id is missing
          }

          // Clean up the title by removing " - Google Search"
          const cleanedTitle = d.title.replace(' - Google Search', '');
          let postId = d.visitItem[0].id; // Use visitItem[0].id as the doc ID
          d.postId = postId;
          d.title = cleanedTitle;
          d.visitTime = d.visitItem[0].visitTime;

          updatedData.push(d); // Push the updated object to the updatedData array

        }
        for (const d of data) {
          const cleanedTitle = d.title.replace(' - Google Search', '');
          let postId = d.visitItem[0].id;
          // **Only check Firebase documents until we find one that exists**

          const postRef = doc(db, 'posts', postId);
          const docSnap = await getDoc(postRef);


          // Check if this info has been gotten before

          if (!docSnap.exists()) {
            // If the info is new, make a doc in the database
            const newDocData = {
              title: cleanedTitle,
              url: d.url,
              date: d.date,
              visitTime: d.visitItem[0].visitTime,
              postId: postId,
              createdAt: new Date(),
              updatedAt: new Date(),
              userGID: d.userGID,
              emojiReactions: {
                heart: 0,
                question: 0,
                surprise: 0,
              },
            };

            // Set the document with the unique visitItem.id
            await setDoc(postRef, newDocData);
            console.log(`Document created with ID: `, postId);
          } else {
            // Document already exists, mark that we found a document and break the loop
            console.log('Document already exists with ID: ', postId);
            break; // Exit early since the remaining items should already exist
          }

        }

        // **Update the local state for ALL data** (whether or not the Firebase check ran fully)
        this.history = updatedData;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    toggleView(view) {
      this.currentView = view;
      console.log("view is now: ", this.currentView)
    },
    connectSSE() {
      const sse = new EventSource('https://staging.searchhistory.site/sse');
      console.log("SSE event added")

      // Listen for visitor count updates
      sse.addEventListener('updateCurrVisitors', event => {
        console.log("curr visitor event listener on")
        const data = JSON.parse(event.data);
        this.activeUsers = data.activeUsers;
      });

      // Listen for cumulative visitor count updates
      sse.addEventListener('updateTotalVisitors', event => {
        const data = JSON.parse(event.data);
        this.totalVisitors = data.totalVisitors;
      });
    },
    handleScroll() {
      const currentScrollY = window.scrollY;
      if (currentScrollY > this.lastScrollY) {
        this.scrollDirection = "scroll-down";
      } else if (currentScrollY < this.lastScrollY) {
        this.scrollDirection = "scroll-up";
      }
      this.lastScrollY = currentScrollY;
    }
  },
};
</script>

<style>
@font-face {
  font-family: 'Mukta';
  src: url('@/assets/fonts/Mukta-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Chirp';
  src: url('@/assets/fonts/chirp-regular-web.woff') format('woff');
}

/* Your existing styles remain unchanged */

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* Ensure full height */
  width: 100%;
  /* Ensure full width */
  box-sizing: border-box;
  /* Prevent padding/border from affecting dimensions */
}

#app-container {
  display: grid;
  grid-template-columns: 20% 1fr 30%;
  grid-template-areas: "left-sidebar main-content right-sidebar";
  height: 100vh;
  font-family: 'Inter', Arial;
  margin: 0 auto;
  max-width: 1200px;
}

#main-content {
  grid-area: "main-content";
}


#header-wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}

@media (max-width: 1024px) {
  #app-container {
    grid-template-columns: 10% 1fr 35%;
    grid-template-areas: "left-sidebar main-content right-sidebar";
  }
}

@media (max-width: 768px) {
  #app-container {
    grid-template-columns: 1fr;
    grid-template-areas: "main-content";
  }

  #main-content {
    width: 100%;
  }
}
</style>
